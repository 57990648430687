@use '@/styles/utils/mixins.scss' as *;

.pageSection {
  scroll-margin-top: 7.5rem;
}

.serviceSectionWrapper {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  .sectionHeadingContainer {
    display: flex;
    gap: 2.5rem;
    justify-content: space-between;
    @include tab() {
      flex-direction: column;
    }
  }
  .sectionDescription {
    max-width: 29rem;
  }
  .cardsList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5rem;
    @include mob() {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
